<template>
  <b-card>
    <b-modal
      ref="search-modal"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="selectedItem.id > 0 ? $t('accounts') : $t('accounts')"
    >
      <b-row>
        <b-col cols="12" md="4" class="pb-1">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              :clearable="true"
              :placeholder="$t('search')"
            />
          </div>
        </b-col>
        <b-col cols="12" class="medium-window">
          <b-table
            ref="accountsTable"
            :items="subAccounts"
            :fields="tableColumns"
            :filter="searchQuery"
            :filter-included-fields="filterOn"
            :tbody-tr-class="
              (item) => {
                if (item && item.isDistributable === true)
                  return 'bg-light-primary';
              }
            "
          >
            <template #head(actions)>
              <span></span>
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="{ item }">
              <b-button
                data-action-type="save"
                size="sm"
                variant="outline-primary"
                @click="
                  (val) => {
                    addItemToItemsTable(item);
                  }
                "
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end py-1 pb-2">
          <b-button class="mx-1" variant="secondary" @click="closeModal">
            {{ $t("Close") }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <g-form @submit="save">
      <b-row>
        <b-col
          v-if="selectedItem.id > 0"
          md="4"
        >
          <!-- code  -->
          <g-field
            id="code"
            type="number"
            :value.sync="selectedItem.code"
            label-text="code"
            name="code"
            disabled
          />
        </b-col>
        <!-- date  -->
        <b-col md="4" readonly>
          <g-field
            :value="getDate(selectedItem.voucherDate)"
            label-text="date"
            disabled
            name="date"
          />
        </b-col>
        <b-col md="4">
          <g-field
            :value.sync="selectedItem.transactionTime"
            label-text="transactionTime"
            name="transactionTime"
            readonly
          />
        </b-col>

        <!-- <b-col md="4">
          <g-field
            id="reference"
            :value.sync="selectedItem.sourceTransactionCode"
            label-text="reference"
            name="reference"
            disabled
          />
        </b-col> -->
        <!-- <label class="col-form-label pt-0">{{$t('reference')}}</label>
        <b-form-input :disabled="!!id" v-model="selectedItem.sourceTransactionCode" type="number"></b-form-input> -->

        <b-col md="4">
          <g-field
            id="sourceTransactionType"
            :value.sync="sourceTransactionType"
            label-text="transactionType"
            name="sourceTransactionType"
            disabled
          />
        </b-col>
        <!-- <b-col md="4" v-if="id">
          <g-field
            label-text="createdBy"
            :value="selectedItem.createdUserName"
            placeholder="createdBy"
            name="createdBy"
            readonly
          />
        </b-col>
        <b-col md="4" v-if="id && selectedItem.updatedUserName">
          <g-field
            label-text="updatedBy"
            :value="selectedItem.updatedUserName"
            placeholder="updatedBy"
            name="updatedBy"
            readonly
          />
        </b-col> -->
      </b-row>
      <b-row class="pt-1 mb-2">
        <!-- notes -->
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.description"
              label-text="notes"
              rows="2"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-between">
        <b-col md="6">
          <div class="d-flex align-items-center justify-content-start">
            <vue-autosuggest
              :suggestions="filteredOptions"
              :input-props="inputProps"
              v-model="query"
              @selected="onSelected"
              @click="clickHandler"
              @input="onInputChange"
            >
              <template slot-scope="{ suggestion }">
                <span class="my-suggestion-item">{{
                  suggestion.item.name
                }}</span>
              </template>
            </vue-autosuggest>
          </div>
        </b-col>
        <b-col
          md="4"
          class="d-flex align-lines-center justify-content-end mb-1 mb-md-0"
        >
          <b-button
            variant="gradient-primary"
            @click="
              (v) => {
                $refs['search-modal'].show();
              }
            "
          >
            <feather-icon icon="PlusSquareIcon" class="mr-50" />
            {{ $t("addAccount") }}
          </b-button>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <!-- second table -->
        <b-table
          primary-key="id"
          show-empty
          striped
          foot-clone
          hover
          fixed
          small
          ref="lines-table-default"
          :items="selectedItem.lines"
          :fields="secondTableColumns"
          :is-busy="isTableBusy"
          :noAction="true"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :empty-text="$t('noMatchingRecordsFound')"
          :tbody-tr-class="
            (item) => {
              if (
                item &&
                item.isDistributable === true &&
                item.costCenters.length > 0
              )
                return 'bg-light-primary';
              if (
                saveClicked === true &&
                item &&
                item.isDistributable === true &&
                item.costCenters.length === 0
              )
                return 'bg-light-warning';
            }
          "
        >
          <template #cell(accountArabicName)="{ item }">
            <g-field
              class="mb-0"
              v-if="item.accountId && item.finalAccountType !== 'budget'"
              disabled
              :value.sync="item.accountId"
              :dir="isRight ? 'rtl' : 'ltr'"
              field="select"
              name="subAccounts"
              :clearable="false"
              :options="initAccounts"
              label="arabicName"
              @change="
                (v) => {
                  item.accountCode = v.code;
                  item.finalAccountType = v.finalAccountType;
                  item.isDistributable = v.isDistributable;
                }
              "
            />
            <g-field
              class="mb-0"
              v-else
              :value.sync="item.accountId"
              :dir="isRight ? 'rtl' : 'ltr'"
              field="select"
              name="subAccounts"
              :clearable="false"
              :options="subAccounts"
              label="arabicName"
              @change="
                (v) => {
                  item.accountCode = v.code;
                  item.finalAccountType = v.finalAccountType;
                  item.isDistributable = v.isDistributable;
                }
              "
            />
          </template>
          <template #cell(debit)="{ item }">
            <g-field
              class="mb-0"
              :value.sync="item.debit"
              rules="required|min_value:0"
              :shortDesc="true"
              :disabled="disableValueDebit(item)"
              type="number"
              @input="
                (v) => {
                  if (!item.credit) {
                    item.credit = 0;
                  }
                }
              "
              @change="
                (v) => {
                  item.debit = fraction(item.debit);
                }
              "
            />
          </template>
          <template #cell(credit)="{ item }">
            <g-field
              class="mb-0"
              :value.sync="item.credit"
              rules="required|min_value:0"
              :shortDesc="true"
              :disabled="disableValueCredit(item)"
              type="number"
              @input="
                (v) => {
                  if (!item.debit) {
                    item.debit = 0;
                  }
                }
              "
              @change="
                (v) => {
                  item.credit = fraction(item.credit);
                }
              "
            />
          </template>
          <template #cell(proofNumber)="{ item }">
            <g-field
              class="mb-0"
              :value.sync="item.proofNumber"
              :shortDesc="true"
              type="text"
            />
          </template>
          <template #cell(description)="{ item }">
            <g-field
              class="mb-0"
              :value.sync="item.description"
              :shortDesc="true"
              type="text"
            />
          </template>
          <template #head(actions)>
            <span></span>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="{ item }">
            <div class="text-nowrap">
              <b-row>
                <b-col v-if="item.finalAccountType === 'budget'">
                  <feather-icon
                    :id="`invoice-row-${item.id}-trash-icon`"
                    icon="TrashIcon"
                    stroke="red"
                    class="mx-1 clickable danger"
                    @click="removeItemFromTable(item)"
                  />
                  <b-tooltip
                    :title="$t('delete')"
                    placement="bottom"
                    :target="`invoice-row-${item.id}-Trashicon`"
                  />
                </b-col>
              </b-row>
            </div>
          </template>
          <template #foot()>
            <span> </span>
          </template>
          <template #foot(debit)>
            <span> {{ fraction(totalDebit) }} </span>
          </template>
          <template #foot(credit)>
            <span> {{ fraction(totalCredit) }} </span>
          </template>
          <template #foot(actions)>
            <span> {{ fraction(totalCredit - totalDebit) }} </span>
          </template>
        </b-table>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            @click="print(id)"
            variant="gradient-primary"
            v-if="id || currentBranch.isMaster"
          >
            <feather-icon icon="PrinterIcon" class="mr-50" />
            {{ $t("print") }}
          </b-button>
          <b-button
            @click="save('saveAndPrint')"
            class="ml-1"
            v-if="selectedItem.isCanceled !== true || isRequested"
            v-show="!selectedItem.sourceTransactionCode"
            variant="outline-primary"
            data-action-type="saveAndPrint"
            v-permission="$route.meta.permission"
          >
            <feather-icon icon="PrinterIcon" class="mr-50" />
            {{ $t("saveAndPrint") }}
          </b-button>
          <b-button
            class="ml-1"
            type="submit"
            variant="gradient-primary"
            :disabled="
              selectedItem.isCanceled === true || isRequested
            "
            data-action-type="save"
          >
            <feather-icon icon="SaveIcon" class="mr-50" />
            {{ $t("save") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import reportMixin from '@/mixin/reportMixin';

export default {
  props: ['id'],
  mixins: [reportMixin],
  data() {
    return {
      isRequested: false,
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: `${this.$t('search')}`,
      },
      datasuggest: [],
      filteredOptions: [],
      limit: 300,
      selected: null,
      totalRows: 1,
      currentPage: 1,
      selectedItem: {
        voucherType: 'journalVoucher',
        isUnderClosure: true,
        voucherDate: this.today,
        transactionTime: '',
        lines: [],
      },
      isTableBusy: false,
      isDisabled: true,
      filterOn: [],
      searchQuery: '',
      subAccounts: [],
      initAccounts: [],
      credit: 0.0,
      debit: 0.0,
      subCostcenters: [],
      deatilObj: {
        value: 0.0,
      },
      accountObj: {
        costCenters: [],
        accountArabicName: '',
        credit: 0,
        debit: 0
      },
      showCostcenterOption: false,
      notAllow: false,
      query: '',
      file: '',
      currentPageList: 1,
      cachedRows: 25,
      cachedPerPage: 1,
      vouchersList: [],
      sourceTransactionType: '',
      saveClicked: false,
      checkIsOpening: false
    };
  },
  watch: {
    'selectedItem.lines': {
      handler(val, oldVal) {
        if (oldVal.length || val.length > oldVal.length || val) {
          if (this.selectedItem.lines.length > 0) {
            if (this.selectedItem.lines[this.selectedItem.lines.length - 1].accountId !== null) {
              this.selectedItem.lines.push({
                lineSerial: this.selectedItem.lines.length + 1,
                accountId: null,
                accountCode: '',
                accObj: null,
                accountArabicName: null,
                accountEnglishName: null,
                isDistributable: null,
                // credit: 0.0,
                // debit: 0.0,
                credit: this.totalDebit > this.totalCredit ? this.fraction(this.totalCredit - this.totalDebit) < 0 ? -this.fraction(this.totalCredit - this.totalDebit) : this.fraction(this.totalCredit - this.totalDebit) : 0.0,
                debit: this.totalCredit > this.totalDebit ? this.fraction(this.totalCredit - this.totalDebit) < 0 ? -this.fraction(this.totalCredit - this.totalDebit) : this.fraction(this.totalCredit - this.totalDebit) : 0.0,
                description: '',
                proofNumber: '',
                costCenters: [],
              })
              this.totalRows = this.selectedItem.lines.length + 1
            }
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    currentId() {
      return this.id === this.selectedItem.id
        ? this.id
        : this.selectedItem.id;
    },
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('name'),
          sortable: true,
        },
        {
          key: 'currentBalance',
          label: this.$t('currentBalance'),
          sortable: true,
          formatter: (value, key, item) => {
            item.currentBalance = item.currentDebit
              + item.prevDebit
              - (item.currentCredit + item.prevCredit);
            return item.currentBalance
          },
        },
        { key: 'actions' },
      ];
    },
    secondTableColumns() {
      return [
        {
          key: 'lineSerial',
          label: this.$t('serialIndicator'),
          sortable: false,
          thStyle: { width: '50px' }
        },
        {
          key: 'accountCode',
          label: this.$t('code'),
          sortable: false,
          thStyle: { width: '100px' }
        },
        {
          key: 'accountArabicName',
          label: this.$t('name'),
          sortable: false,
          thStyle: { width: '25%' }
        },
        {
          key: 'debit',
          label: this.$t('debit'),
          sortable: false,
          type: 'number',
          footer: () => this.fraction(this.totalDebit),
        },
        {
          key: 'credit',
          label: this.$t('credit'),
          sortable: false,
          type: 'number',
          footer: () => this.fraction(this.totalCredit),
        },
        {
          key: 'proofNumber',
          label: this.$t('proofNumber'),
          sortable: false,
          thStyle: { width: '100px' }
        },
        {
          key: 'description',
          label: this.$t('notes'),
          sortable: false,
          thStyle: { width: '25%' }
        },
        {
          key: 'actions',
          footer: () => this.fraction(this.totalCredit - this.totalDebit),
        },
      ];
    },
    remindered() {
      if (this.accountObj.debit) {
        return parseFloat(this.accountObj.debit - this.totalCostcenters) || 0
      }
      if (this.accountObj.credit) {
        return parseFloat(this.accountObj.credit - this.totalCostcenters) || 0;
      }
      return this.remindered
    },
    totalDebit() {
      let total = 0;
      this.selectedItem.lines.forEach((item) => {
        total += parseFloat(item.debit) || 0;
      });
      return total;
    },
    totalCredit() {
      let total = 0;
      this.selectedItem.lines.forEach((item) => {
        total += parseFloat(item.credit) || 0;
      });
      return total;
    },
    totals() {
      return Number(this.fraction(this.totalCredit - this.totalDebit));
    }
  },
  beforeMount() {
    if (this.currentYear.year) {
      this.getInitAccounts()
    }
  },
  mounted() {
    this.sourceTransactionType = this.$t('closedVoucher');
    this.saveClicked = false
    this.selectedItem.transactionTime = this.getTime();
    this.getAccounts();
    if (this.id > 0) {
      this.getData();
    } else {
      this.selectedItem.voucherDate = this.currentYear.endDate;
      this.selectedItem.branchId = this.branchId;
      if (this.currentYear) {
        this.selectedItem.description = this.$t('closedVoucherForYear', {
          year: this.currentYear.year,
        })
      }
    }
  },
  methods: {
    getItemChange(i) {
      this.accountObj = {
        costCenters: [],
        accountArabicName: '',
        credit: 0,
        debit: 0
      };
      this.showCostcenterOption = false;
      this.selectedItem = this.vouchersList[i - 1];
      this.selectedItem.transactionTime = this.getTime(this.vouchersList[i - 1].transactionTime);
    },
    disableValueCredit(item) {
      if (this.profile.permissions.indexOf('integratedDailyJournalUpdate') > -1 || this.profile.isAdmin) {
        if (item.finalAccountType !== 'budget' && item.accountId) {
          return true
        }
        return !!Number(item.debit);
      }
      if (item.finalAccountType !== 'budget' && item.accountId) {
        return true
      }
      return !!Number(item.debit);
    },
    disableValueDebit(item) {
      if (this.profile.permissions.indexOf('integratedDailyJournalUpdate') > -1 || this.profile.isAdmin) {
        if (item.finalAccountType !== 'budget' && item.accountId) {
          return true
        }
        return !!Number(item.credit);
      }
      if (item.finalAccountType !== 'budget' && item.accountId) {
        return true
      }
      return !!Number(item.credit);
    },
    checkSourcePerm() {
      if (!this.profile.isAdmin && this.selectedItem.sourceTransactionCode > 0
        && this.profile.permissions.indexOf('integratedDailyJournalUpdate') > -1) {
        return false
      }
      if (this.profile.isAdmin) {
        return false
      }
      return true
    },
    clickHandler() {
    },
    onSelected(option) {
      this.addItemToItemsTable(option.item);
      this.query = '';
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredItemsData = this.subAccounts.filter(item => item.code.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredItemsDataName = this.subAccounts.filter(item => item.arabicName.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredItemsData.concat(filteredItemsDataName)
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    getData() {
      if (this.id) {
        this.get({
          url: 'Vouchers',
          id: this.id,
        }).then((data) => {
          this.selectedItem = data;
          if (this.selectedItem.lines.length > 25 && (this.id || this.currentId)) {
            this.totalRows = data.lines.length + 1;
          }
          this.selectedItem.transactionTime = this.getTime(data.transactionTime);
        });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addItemToItemsTable(item) {
      const _index = this.selectedItem.lines.findIndex(i => i.accountId === null);
      this.selectedItem.lines.splice(_index, 1);
      this.selectedItem.lines.push({
        lineSerial: this.selectedItem.lines.length + 1,
        accountId: item.id,
        accountCode: item.code,
        accObj: item,
        finalAccountType: item.finalAccountType,
        accountArabicName: item.arabicName,
        accountEnglishName: item.englishName,
        isDistributable: item.isDistributable,
        credit: item.credit ? item.credit : 0.0,
        debit: item.debit ? item.debit : 0.0,
        description: item.description ? item.description : '',
        proofNumber: '',
        costCenters: [],
      });
      if (this.selectedItem.lines.length > 25 && (this.id || this.currentId)) {
        this.totalRows = this.selectedItem.lines.length + 1
      }
    },
    removeItemFromTable(addedItem) {
      const filteredItems = this.selectedItem.lines.reduce((items, val) => {
        if (val !== addedItem) {
          const index = items.length > 0 ? items[items.length - 1].lineSerial : 0;
          val.lineSerial = index + 1;
          items.push(val);
        }
        if (this.selectedItem.lines.length > 25 && (this.id || this.currentId)) {
          this.totalRows = items.length - 1;
        }
        return items;
      }, []);
      this.selectedItem.lines = filteredItems;
      addedItem.costCenters = [];
      this.showCostcenterOption = false;
    },
    save(type) {
      // validate
      var _account = this.selectedItem.lines.find((account) => (Number.parseFloat(account.debit) !== 0 || Number.parseFloat(account.credit) !== 0) && account.accountId === null);
      if (_account) {
        this.doneAlert({ text: this.$t('mustSelectAccountAtRow', { rowNo: _account.lineSerial }), type: 'warning' });
        return;
      }
      if (this.totals !== 0) {
        this.doneAlert({ text: this.$t('Constraint Unbalanced'), type: 'error' });
        return;
      }

      this.confirmSave(type);
    },
    checkZeroVouchers() {
      this.selectedItem.lines.forEach(item => {
        if (item.debit === 0 && item.credit === 0) {
          this.notValidLine = true;
        } else {
          this.notValidLine = false;
        }
      });
      if (this.notValidLine) {
        // alert here
        this.doneAlert({ text: this.$t('notAloowedToSaveWithZeroAccounts'), type: 'error' });
        this.isRequested = false;
        return false;
      }
      return true;
    },
    confirmSave(type) {
      if (this.selectedItem.lines[this.selectedItem.lines.length - 1].accountId === null) {
        this.selectedItem.lines = this.selectedItem.lines.slice(0, -1)
      }
      if (!this.currentId) {
        this.selectedItem.transactionTime = this.time;
      }
      if (!this.checkForFiscalYearPermForTrans(this.selectedItem.voucherDate)) return;
      this.isRequested = true;
      if (this.selectedItem.id) {
        this.update({
          url: 'Vouchers',
          data: this.selectedItem,
          id: this.selectedItem.id,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('updatedSuccessfully') });
            if (type === 'saveAndPrint') this.print(this.selectedItem.id);
            this.$router.push({ name: 'vouchers' });
          }).finally(() => {
            this.isRequested = false;
            this.saveClicked = false
          })
      } else {
        this.selectedItem.transactionTime = this.time;
        this.create({
          url: 'Vouchers',
          data: this.selectedItem,
        })
          .then((dataId) => {
            this.doneAlert({ text: this.$t('savedSuccessfully') });
            if (type === 'saveAndPrint') this.print(dataId);
            this.$router.push({ name: 'vouchers' });
          }).finally(() => {
            this.saveClicked = false
          })
      }
    },
    getAccounts() {
      this.get({ url: 'Accounts/final-account-type/2' }).then((data) => {
        data.forEach(item => {
          item.name = `${item.code} - ${item.arabicName}`
        });
        this.subAccounts = data;
        this.subAccounts.sort((a, b) => a.code - b.code);
      });
    },
    getInitAccounts() {
      const url = this.id ? 'aggregates/accounts' : 'Accounts/final-account-type/1'
      this.get({ url: url }).then((data) => {
        data = this.id ? data.accounts : data;
        data.forEach(item => {
          item.name = `${item.code} - ${item.arabicName}`
        });
        this.initAccounts = data;
        if (!this.id) {
          this.initAccounts.forEach(element => {
            this.selectedItem.lines.push({
              lineSerial: this.selectedItem.lines.length + 1,
              accountId: element.id,
              accountCode: element.code,
              accObj: element,
              accountArabicName: element.arabicName,
              accountEnglishName: element.englishName,
              isDistributable: element.isDistributable,
              // credit: element.nature === 'debit' && element.balance > 0 ? element.balance : 0.0,
              // debit: element.nature === 'credit' && element.balance > 0 ? element.balance : 0.0,
              debit: element.balance < 0 ? -element.balance : 0.0,
              credit: element.balance > 0 ? element.balance : 0.0,
              description: element.description ? element.description : '',
              proofNumber: '',
              costCenters: [],
            });
          });
        }
        this.initAccounts.sort((a, b) => a.code - b.code);
      });
    },
    openModal() {
      this.$refs['search-modal'].show();
    },
    closeModal() {
      this.searchQuery = ''
      this.$refs['search-modal'].hide();
    },
    print(id) {
      const printedItem = {
        id: id
      }
      this.printReport(this.isRight ? 'VouchersReport-ar' : 'VouchersReport-en', printedItem);
    }
  },
};
</script>

<style lang="css">
#autosuggest {
  width: 75% !important;
}
.autosuggest__results-container .autosuggest__results {
  background-color: #fff;
  margin-top: 1rem;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
  position: absolute;
  width: 100%;
  overflow-y: auto;
  max-height: 40vh;
  z-index: 999;
}
.autosuggest__results-container .autosuggest__results ul li:hover {
  background: #ededed;
  cursor: pointer;
}
.autosuggest__results-container .autosuggest__results ul li {
  list-style: none;
  padding: 0.75rem 1rem;
  cursor: pointer;
}
.autosuggest__results-container .autosuggest__results ul {
  padding-left: 0;
  margin-bottom: 0;
}
</style>
